import { useApplicationContext } from '@/context/ApplicationContext';
import useTranslation from '@/utils/i18n/useTranslation';
import { gql } from '@apollo/client';
import { Button } from '@chakra-ui/react';
import { useState } from 'react';
import CustomSearchableSelect, { CustomSearchableSelectOption } from './CustomSearchableSelect';
import { SuspenseWithSpinner } from './SuspenseWithSpinner';
import { useUserSelectInputSuspenseQuery } from './UserSelectInput.generated';

export type UserType = {
  userId: string;
};

type UserSelectInputProps = {
  defaultUserValues: UserType[];
  onChange: (usersValue: UserType[]) => void;
  label?: string;
};

type optionType = {
  value: string;
  label: string;
};

gql`
  query UserSelectInput {
    users {
      id
      name
    }
  }
`;

const UserSelectInput = (props: UserSelectInputProps) => {
  const { me } = useApplicationContext();
  const {
    data: { users },
  } = useUserSelectInputSuspenseQuery();
  const { t } = useTranslation();
  const { defaultUserValues, onChange, label = t('assignee') } = props;

  const initialUsers = () => {
    if (users.length === 0) return [];
    return defaultUserValues.map((value) => {
      const user = users.find((user) => user.id === value.userId);
      if (!user) throw new Error('user not found');
      return {
        value: user.id,
        label: user.name,
      };
    });
  };

  const [userValues, setUserValues] = useState<CustomSearchableSelectOption[]>(initialUsers);
  if (users === undefined) return null;

  const options = users.map((user) => ({ value: user.id, label: user.name }));

  const handleInput = (values: CustomSearchableSelectOption[]) => {
    if (values.length > 0) {
      onChange(values.map(({ value }) => ({ userId: value as string })));
      setUserValues(values);
      return;
    }
    onChange([]);
    setUserValues([]);
  };

  const onAssignToMe = () => {
    if (!me) return;

    const _me: optionType = { value: me.id, label: me.name };
    handleInput([...userValues, _me]);
  };

  const isShowAssignToMe = me && !userValues.find((user) => user.value === me.id);

  return (
    <>
      <CustomSearchableSelect
        label={label}
        placeholder={t('warning.please-select')}
        value={userValues}
        onChange={handleInput}
        options={options}
        isClearable
        isMulti
      />
      {isShowAssignToMe && (
        <Button size='sm' variant='link' colorScheme='primary' p='1' onClick={onAssignToMe}>
          {t('assign-to-me')}
        </Button>
      )}
    </>
  );
};

const UserSelectInputWithSpinner = (props: UserSelectInputProps) => (
  <SuspenseWithSpinner>
    <UserSelectInput {...props} />
  </SuspenseWithSpinner>
);

export default UserSelectInputWithSpinner;
